<template>
  <RecordViewWrapper>
    <CardToolbox>
      <SearchHeaderStyle>
        <sdPageHeader title="bank Operations">+
          <template #buttons>
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="toggelSupportModal" key="1">Support</a-menu-item>
                  <a-menu-item @click="toggelOperationModal" key="2">Operation</a-menu-item>
                </a-menu>
              </template>
              <a-button type="primary">
                Add Outcome
                <DownOutlined />
              </a-button>
            </a-dropdown>
            <a-dropdown>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="toggelEntryModal" key="2">Entry</a-menu-item>
                </a-menu>
              </template>
              <a-button type="primary">
                Add Income
                <DownOutlined />
              </a-button>
            </a-dropdown>
          </template>
        </sdPageHeader>
      </SearchHeaderStyle>
    </CardToolbox>
    <Main>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards title="Filters ">
            <a-form :model="filterForm" layout="vertical">
              <a-row :gutter="25">
                <a-col :span="6">
                  <a-form-item label="Bank Account" name="bankAccountId">
                    <a-select v-model:value="filterForm.bankAccountId" placeholder="Bank Account">
                      <a-select-option v-for="(bank, index) in AccountApi" :key="index" :value="bank.id">
                        {{ bank.bankName }} &nbsp;-&nbsp; {{ bank.name }}</a-select-option>

                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item label="Expense" name="incomeOutcome">
                    <a-select v-model:value="filterForm.incomeOutcome" placeholder="Expense">
                      <a-select-option :value="1">
                        Income
                      </a-select-option>
                      <a-select-option :value="2">
                        Outcome
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :xs="6"><a-form-item label="Benificiary" name="isPartnerOrOccasional">
                    <a-select :size="'small'" v-model:value="filterForm.isPartnerOrOccasional"
                      placeholder="Please choose partner">
                      <a-select-option :value="1">
                        Partner
                      </a-select-option>
                      <a-select-option :value="2">
                        Occasional
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :xs="6" v-if="filterForm.isPartnerOrOccasional == 1">
                  <a-form-item label="Partner" name="partenaireId">
                    <a-select v-if="partnerApi.length" v-model:value="filterForm.partenaireId"
                      placeholder="Please choose Owner" show-search style="width: 100%" :options="partnerData"
                      :not-found-content="partnerFetching ? undefined : null
                        " :filter-option="false"
                      @search="(value) => searchPartner(value, filterForm.operationSupport == 1, filterForm.operationSupport == 2)">
                      <template v-if="partnerFetching" #notFoundContent>
                        <a-spin size="small" />
                      </template>
                    </a-select>
                    <a-select v-else v-model:value="filterForm.partenaireId" placeholder="Please choose Owner">
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="6" :xs="6" v-if="filterForm.isPartnerOrOccasional == 2">
                  <a-form-item ref="occasionalName" label="Occasional Name" name="occasionalName">
                    <a-input style="height: 38px" v-model:value="filterForm.occasionalName" />
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item name="date" label="Date From">
                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateStart" :style="{ width: '100%' }"
                      format="DD/MM/YYYY" />
                  </a-form-item>
                </a-col>
                <a-col :span="6">
                  <a-form-item name="date" label="Date To">
                    <a-date-picker :picker="'start'" v-model:value="filterForm.dateEnd" :style="{ width: '100%' }"
                      format="DD/MM/YYYY" />
                  </a-form-item>
                </a-col>

                <a-col :span="6" class="button-container">
                  <a-button class="btn ant-btn-lg client-form-submit" outlined type="primary" @click="onFilter()">
                    <span>Filter</span>
                  </a-button>
                </a-col>
                <a-col :span="6" class="button-container">
                  <a-button class="ant-btn-lg" @click="clearFilters()" danger>
                    <span>Clear Filters</span>
                  </a-button>
                </a-col>
              </a-row>
            </a-form>
          </sdCards>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col class="w-100" :md="24">
          <sdCards headless>
            <div>
              <TableWrapper class="table-data-view table-responsive">
                <a-table @change="onHandleTableChange" :pagination="pagination" :dataSource="dataSource"
                  :columns="columns">
                  <template #footer>
                    <div class="table-footer">
                      <div class="table-footer-right">
                        <div class="label">
                          Total Income : {{ allTotalIncome }}
                        </div>
                        <div class="label">
                          Total Outcome : {{ allTotalOutcome }}
                        </div>
                      </div>
                      <div class="table-footer-left">
                        <div class="label">
                          Table Total Income : {{ totalIncome }}
                        </div>
                        <div class="label">
                          Table Total Outcome : {{ totalOutcome }}
                        </div>
                      </div>
                    </div>
                  </template>
                </a-table>
              </TableWrapper>
            </div>
          </sdCards>
        </a-col>
      </a-row>
      <TransactionModal v-if="showTransactionModal" entity="Product" :entityId="targetProduct" @Cancel="toggelHistory">
      </TransactionModal>
      <Support v-if="showSupportModal" :bankOperation="selectedBankOperation" :pagination="pagination"
        @cancel="toggelSupportModal">
      </Support>
      <Operation v-if="showOperationModal" :bankOperation="selectedBankOperation" :pagination="pagination"
        @cancel="toggelOperationModal">
      </Operation>
      <Entry v-if="showEntryModal" :bankOperation="selectedBankOperation" :pagination="pagination"
        @cancel="toggelEntryModal">
      </Entry>
    </Main>
  </RecordViewWrapper>
</template>
<script>
import {
  FormValidationWrap,
  VerticalFormStyleWrap,
} from "../forms/overview/Style";
import { Main, TableWrapper, CardToolbox, SearchHeaderStyle } from "../styled";
import { DownOutlined } from '@ant-design/icons-vue';
import { RecordViewWrapper } from "../../view/crud/axios/style";
import { useStore } from "vuex";
import { computed, onMounted, reactive, ref, defineAsyncComponent } from "vue";
import usePartnerSearch from '@/composable/usePartnerSearch';
import moment from "moment";


const TransactionModal = defineAsyncComponent(() => import("./TransactionModal.vue"));
const Support = defineAsyncComponent(() => import("./bankOperations/Support.vue"));
const Operation = defineAsyncComponent(() => import("./bankOperations/Operation.vue"));
const Entry = defineAsyncComponent(() => import("./bankOperations/Entry.vue"));
const GenericFilter = defineAsyncComponent(() => import("@/components/businessComponents/GenericFilter.vue"));

const dateFormat = "YYYY/MM/DD";
const dateFormatTime = "HH:mm";


const columns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    sorter: true,
  },
  {
    title: "Bank Account",
    dataIndex: "bankOperation.bankAccountName",
    key: "bankOperation.bankAccountName",
  },
  {
    title: "In/Out",
    dataIndex: "incomeOutcome",
    key: "incomeOutcome",
  },
  {
    title: "Benificiary ",
    dataIndex: "benificiary",
    key: "benificiary",
  },
  {
    title: "Expense Type",
    dataIndex: "bankOperation.bankOperationTypeName",
    key: "bankOperation.bankOperationTypeName",
  },
  {
    type: "number",
    title: "Amount",
    dataIndex: "bankOperation.amount",
    key: "bankOperation.amount",
  },
  {
    title: "Actions",
    dataIndex: "action",
    key: "action",
    width: "90px",
  },
];

const bankOperations = {
  name: "bankOperations",
  components: {
    Main,
    TableWrapper,
    FormValidationWrap,
    VerticalFormStyleWrap,
    RecordViewWrapper,
    CardToolbox,
    SearchHeaderStyle,
    TransactionModal, DownOutlined,
    Support,
    Operation,
    GenericFilter,
    Entry
  },
  setup() {
    const { state, dispatch } = useStore();
    const searchValue = ref("");
    const selectedBankOperation = ref(null);
    const AccountApi = computed(() => state.AccountApi.data);
    const ExpenseTypeApi = computed(
      () => state.ExpenseTypeApi.data
    );
    const PaymentMethodApi = computed(() => state.PaymentMethodApi.data);

    var bankOperationPagination = computed(() => state.BankOperationApi.data);
    var BankOperationApi = computed(() => state.BankOperationApi.data.data);
    var totalIncome = computed(() => state.BankOperationApi.totalIncome);
    var totalOutcome = computed(() => state.BankOperationApi.totalOutcome);
    var allTotalIncome = computed(() => state.BankOperationApi.allTotalIncome);
    var allTotalOutcome = computed(() => state.BankOperationApi.allTotalOutcome);
    const isLoading = computed(() => state.BankOperationApi.loading);
    const partnerApi = computed(() => state.partnerApi.data);
    const filters = ref([
      { label: "Bank Account", name: 'BankAccountId', type: 'apiList', value: '', options: AccountApi },
    ]);

    var loading = false;
    const localState = reactive({
      visible: false,
      editVisible: false,
      modalType: "primary",
      url: null,
    });


    onMounted(() => {
      dispatch("paymentMethodInfo");
      dispatch("partnerInfo");
      dispatch("accountInfo");
      dispatch("expenseTypeInfo");
      dispatch("bankOperationGetTotal", pagination.value);
      dispatch("bankOperationGetPagedData", pagination.value);
    });

    const sorted = ref({
      columnKey: "",
      order: "",
    });

    const pagination = computed(() => {
      const defaultPagination = {
        current: 1,
        filters: filterForm,
        pageSize: 10,
        OrderBy: sorted.value.columnKey,
        searchValue: "",
        orderBy: "",
        sorter: {
          order: sorted.value.order == "descend" ? "desc" : "asc",
        },
      };

      if (bankOperationPagination.value && bankOperationPagination.value.succeeded) {
        return {
          ...defaultPagination,
          current: bankOperationPagination.value.pageNumber,
          total:
            bankOperationPagination.value.recordsFiltered ??
            bankOperationPagination.value.recordsTotal,
        };
      }

      return defaultPagination;
    });


    const onHandleSearch = () => {
      sorted.value.order = "ascend";
      dispatch("bankOperationGetPagedData", {
        ...pagination.value,
        current: 1,
        OrderBy: sorted.value.order,
        searchValue: searchValue.value,
      });
    };

    const showModal = () => {
      localState.visible = true;
    };

    const handleDelete = (id) => {
      const confirm = window.confirm("Are you sure delete this?");
      if (confirm) {
        dispatch("bankOperationApiDataDelete", { id, pagination: pagination.value });
      }
      return false;
    };

    const dataSource = computed(() =>
      BankOperationApi.value && BankOperationApi.value.length
        ? BankOperationApi.value.map((bankOperation, key) => {
          return {
            key: key + 1,
            bankOperation,
            incomeOutcome: bankOperation.incomeOutcome == 1 ? 'Income' : 'Outcome',
            benificiary: bankOperation.partenaireId ? bankOperation.partenaireName : bankOperation.occasionalName,
            date: moment(bankOperation.date).format(dateFormat),
            action: (
              <div class="table-actions">
                <a-button
                  class="edit"
                  onClick={() => handleEdit(bankOperation)}
                >
                  <sdFeatherIcons type="edit" bankOperation={14} />
                </a-button>
                &nbsp;&nbsp;&nbsp;
                <a-button
                  class="delete"
                  onClick={() => handleDelete(bankOperation.id)}
                >
                  <sdFeatherIcons type="trash-2" bankOperation={14} />
                </a-button>
              </div>
            ),
          };
        })
        : []
    );

    const showTransactionModal = ref(false);
    const toggelTransaction = () => {
      showTransactionModal.value = !showTransactionModal.value;
    }
    const showSupportModal = ref(false);
    const toggelSupportModal = () => {
      selectedBankOperation.value = null;
      showSupportModal.value = !showSupportModal.value;
    }
    const showOperationModal = ref(false);
    const toggelOperationModal = () => {
      selectedBankOperation.value = null;
      showOperationModal.value = !showOperationModal.value;
    }
    const showEntryModal = ref(false);
    const toggelEntryModal = () => {
      selectedBankOperation.value = null;
      showEntryModal.value = !showEntryModal.value;
    }
    const handleEdit = (bankOperation) => {
      selectedBankOperation.value = bankOperation;
      if (bankOperation.saleId) {
        showEntryModal.value = !showEntryModal.value;
        return;
      }
      if (bankOperation.operationSupport == 1) {
        showOperationModal.value = !showOperationModal.value;
      } else {
        showSupportModal.value = !showSupportModal.value;
      }
    }

    //// filters 
    var filterForm = reactive({
      dateFrom: "",
      dateTo: "",
      isPartnerOrOccasional: "",
      occasionalName: "",
      incomeOutcome: "",
      bankAccountId: "",
      operationSupport: "",
      partenaireId: "",
      occasionalLocalExternal: "",
    });

    const onFilter = () => {
      sorted.value = {
        columnKey: "date",
        order: "descend",
      };
      filterForm.dateFrom = filterForm.dateStart ? moment(filterForm.dateStart).format('YYYY/MM/DD') : "";
      filterForm.dateTo = filterForm.dateEnd ? moment(filterForm.dateEnd).format('YYYY/MM/DD') : "";
      pagination.value = {
        ...pagination,
        current: 1,
        orderBy: "date",
        pageSize: 10,
        sorter: {
          order: sorted.value.order == "descend" ? "desc" : "asc",
        },
        filters: filterForm.value,
      }
      dispatch("bankOperationGetPagedData", pagination.value);
      dispatch("bankOperationGetTotal", pagination.value);
    }
    const clearFilters = () => {
      filterForm.dateFrom = "";
      filterForm.dateTo = "";
      filterForm.isPartnerOrOccasional = "";
      filterForm.occasionalName = "";
      filterForm.incomeOutcome = "";
      filterForm.bankAccountId = "";
      filterForm.operationSupport = "";
      filterForm.partenaireId = "";
      filterForm.occasionalLocalExternal = "";
      onFilter();
    }

    const onHandleTableChange = (pagination, filters, sorter) => {
      sorted.value = {
        columnKey: sorter.columnKey ?? "",
        order: sorter.order,
      };

      pagination.value = {
        ...pagination,
        sorter: {
          order: sorted.value.order == "descend" ? "desc" : "asc",
        },
        orderBy: sorted.value.columnKey,
        filters: filterForm.value,
      }

      dispatch("bankOperationGetTotal", pagination);
      dispatch("bankOperationGetPagedData", pagination);
    };
    const { partnerData, searchPartner, partnerFetching } = usePartnerSearch(
      filterForm.partenaireId
    );
    return {
      isLoading,
      handleDelete,
      dataSource,
      columns,
      localState,
      showModal,
      onHandleSearch,
      loading,
      onHandleTableChange,
      searchValue,
      AccountApi,
      dateFormat,
      dateFormatTime,
      partnerApi,
      ExpenseTypeApi,
      showTransactionModal,
      toggelTransaction,
      showSupportModal,
      toggelSupportModal,
      showOperationModal,
      toggelOperationModal,
      selectedBankOperation,
      PaymentMethodApi,
      pagination,
      totalIncome,
      totalOutcome,
      filters,
      filterForm,
      onFilter,
      clearFilters,
      partnerData,
      searchPartner,
      partnerFetching,
      allTotalOutcome,
      allTotalIncome,
      toggelEntryModal,
      showEntryModal
    };
  },
};

export default bankOperations;
</script>
<style scoped>
.table-footer {
  display: flex;
  justify-content: space-between;
  text-align: right;
  font-size: 15px;
  font-weight: bold;
}

.table-footer .table-footer-right {
  text-align: left;

}

.ant-btn-lg {
  height: 38px;
  width: 100%;
}

.button-container {
  place-self: center;
}
</style>